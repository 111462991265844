import EVALMEE_LOGO from "@rails_assets/images/logo_evalmee.png"
import PAPEEZ_LOGO from "@rails_assets/images/logo_papeez2.png"
import DASKIT_LOGO from "@rails_assets/images/login_logo_web.png"

export default {
  computed: {
    currentApp()          { return this.$attrs.current_app },
    isCurrentAppDaskit()  { return this.currentApp === "daskit" || this.matchRouterMetaApp("daskit") },
    isCurrentAppEvalmee() { return this.currentApp === "evalmee" || this.matchRouterMetaApp("evalmee") },
    isCurrentAppPapeez()  { return this.currentApp === "papeez" || this.matchRouterMetaApp("papeez")},

    logoSrc() {
      if (this.isCurrentAppEvalmee) return EVALMEE_LOGO
      if (this.isCurrentAppPapeez)  return PAPEEZ_LOGO
      if (this.isCurrentAppDaskit)  return DASKIT_LOGO
      return ""
    },

    matchRouterMetaApp() {
      return (val) => this.$route.matched.some(m => m.meta.app === val)
    },
  },

}
